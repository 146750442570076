<template>
    <div class="content-page">
        <div class="content-nav">
            <el-breadcrumb class="breadcrumb" separator="/">
                <el-breadcrumb-item :to="{ name: 'home_ads' }">广告管理</el-breadcrumb-item>
                <el-breadcrumb-item>{{infoForm._id ? '编辑广告' : '添加广告'}}</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="operation-nav">
                <el-button type="primary" @click="onSubmitInfo">确认保存</el-button>
                <el-button @click="goBackPage" icon="arrow-left">返回</el-button>
            </div>
        </div>
        <div class="content-main">
            <div class="form-table-box">
                <el-form ref="infoForm" :rules="infoRules" :model="infoForm" label-width="120px">
                    
                    <el-form-item label="广告缩略图" prop="ads_pic_url" v-if="infoForm.ads_pic_url"
                                  class="image-uploader-diy new-height">
                        <img v-if="infoForm.ads_pic_url" :src="infoForm.ads_pic_url" class="image-show">
                        <el-button class="dele-list-pic" type="primary" @click="delePicList">
                            <i class="fa fa-trash-o"></i>
                        </el-button>
                    </el-form-item>
                    
                    <el-form-item label="广告缩略图" prop="ads_pic_url" v-if="!infoForm.ads_pic_url">
                        <el-upload
                                name="file"
                                class="upload-demo"
                                :action="qiniuZone"
                                :on-success="handleUploadListSuccess"
                                :before-upload="getQiniuToken"
                                list-type="picture-card"
                                :data="picData"
                        >

                        <el-button size="small" type="primary">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">图片长宽：带给出 | 只能上传jpg/png文件</div>
                        </el-upload>
                    </el-form-item>

                    <el-form-item label="广告名" prop="ads_name">
                        <el-input v-model="infoForm.ads_name"></el-input>
                    </el-form-item>


                    <el-form-item label="广告跳转类型" prop="ads_url">

                        <el-radio-group v-model="infoForm.ads_type" @change="typeChanged">
                            <el-radio :label="3">商品</el-radio>
                            <el-radio :label="6">文章</el-radio>
                            <el-radio :label="9">视频</el-radio>
                            <el-radio :label="12">网页</el-radio>
                            <el-radio :label="15">小程序</el-radio>
                        </el-radio-group>
                    </el-form-item>

                        <div v-if="infoForm.ads_type==3" label="关联商品" >
                            <el-form-item label="输入选择商品" prop="goods_name">
                                <el-autocomplete
                                class="inline-input"
                                v-model="infoForm.goods_name"
                                :fetch-suggestions="goodsQuerySearch"
                                @select="handleGoodsSelect"
                                placeholder="请输入商品名">
                                </el-autocomplete>
                            </el-form-item> 
                        </div>
                        
                        <div  v-if="infoForm.ads_type==6" label="关联文章" name="6">
                            <el-form-item label="输入选择文章" prop="article_name">
                                <el-autocomplete
                                    class="inline-input"
                                    v-model="infoForm.article_name"
                                    :fetch-suggestions="articleQuerySearch"
                                    @select="handleArticleSelect"
                                    placeholder="请输入文章名">
                                </el-autocomplete>
                            </el-form-item> 
                        </div>

                        <div  v-if="infoForm.ads_type==9" label="关联视频" name="9">
                            <el-form-item label="输入选择视频" prop="video_name">
                                <el-autocomplete
                                    class="inline-input"
                                    v-model="infoForm.video_name"
                                    :fetch-suggestions="videoQuerySearch"
                                    @select="handleVideoSelect"
                                    placeholder="请输入视频名">
                                </el-autocomplete>
                            </el-form-item> 
                        </div>

                        <div v-if="infoForm.ads_type==12" label="关联网页" name="12">
                            <el-form-item label="网页标题" prop="web_name">
                                <el-input v-model="infoForm.web_name"></el-input>
                            </el-form-item> 

                            <el-form-item label="网页地址" prop="web_url">
                                <el-input v-model="infoForm.web_url"></el-input>
                            </el-form-item> 
                        </div>

                        <div  v-if="infoForm.ads_type==15" label="关联小程序" name="15">

                            <el-form-item label="目标名" prop="mini_target_name">
                                <el-input v-model="infoForm.mini_target_name"></el-input>
                            </el-form-item> 

                            <el-form-item label="小程序appid" prop="mini_appid">
                                <el-input v-model="infoForm.mini_appid"></el-input>
                            </el-form-item> 

                            <el-form-item label="页面路径(带参数)" prop="mini_path">
                                <el-input v-model="infoForm.mini_path"></el-input>
                            </el-form-item> 

                        </div>
                    
                    <el-form-item>
                        <el-button type="primary" @click="onSubmitInfo">确定保存</el-button>
                        <el-button @click="goBackPage">返回列表</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '@/config/api';
    import $ from 'jquery'
    export default {
        data() {
            return {
                root: '',
                qiniuZone:'',
                picData: {
                    token: ''
                },
                uploaderHeader: {
                    'LuoShao-Token': localStorage.getItem('token') || '',
                },
                infoForm: {
                    ads_pic_url: '',
                    ads_name:'',
                    ads_type:3,
                    link_name:'',
                    link_id:'',

                    goods_id:'',
                    goods_name: '',

                    article_id:'',//文章id
                    article_name:'',//文章名称

                    video_id:'',//视频id
                    video_name:'',//视频名称

                    web_url:'',//网页地址
                    web_name:'',//站点目标地址

                    mini_appid:'',//小程序appid
                    mini_path:'',//小程序页面路径，用户传递值
                    mini_target_name:''//小程序目标页面名称


                    // is_index: false,
                },
                preousType:3,
                infoRules: {
                    ads_name: [
                        {required: true, message: '请填写广告名称', trigger: 'blur'},
                    ],
                    ads_pic_url: [
                        {required: true, message: '请上传广告图片', trigger: 'blur'},
                    ],
                    link_name: [
                        {required: true, message: '请选择关联目标', trigger: 'blur'},
                    ],
                },
                goodsNameTipsArray:[],//用于存放下拉提醒框中数据的数组
		        goodsNameArray:[],//页面初始化时全部数据

                videoNameTipsArray:[],//用于存放下拉提醒框中视频数据的数组
		        videoNameArray:[],//页面初始化时全部数据

                articleNameTipsArray:[],//用于存放下拉提醒框中文章数据的数组
		        articleNameArray:[],//页面初始化时全部数据
                
            }
        },
        watch:{
            'infoForm.goods_name':{
            handler: function() {
                    this.goodsNameTipsArray = [];//这是定义好的用于存放下拉提醒框中数据的数组
                    var len = this.goodsNameArray.length;//nameArray是页面初始化时全部数据
                    var arr = [];
                    for (var i = 0; i < len; i++) {//根据输入框中inputName的值进行模糊匹配
                        if (this.goodsNameArray[i].name.indexOf(this.infoForm.goods_name) >= 0) {
                            arr.push(this.goodsNameArray[i]);//符合条件的值都放入arr中
                        }
                    }

                    console.log(arr);
                    
                    //el-autocomplete元素要求数组内是对象，且有value属性，此处做一个格式调整
                    for (var i = 0; i < arr.length; i++) {
                    var obj = { value: "" };
                    obj.value = arr[i].name;
                    obj.id = arr[i]._id;
                    this.goodsNameTipsArray.push(obj);
                    }
                }
            },
            'infoForm.article_name':{
            handler: function() {
                    this.articleNameTipsArray = [];//这是定义好的用于存放下拉提醒框中数据的数组
                    var len = this.articleNameArray.length;//nameArray是页面初始化时全部数据
                    var arr = [];
                    for (var i = 0; i < len; i++) {//根据输入框中inputName的值进行模糊匹配
                        if (this.articleNameArray[i].name.indexOf(this.infoForm.article_name) >= 0) {
                            arr.push(this.articleNameArray[i]);//符合条件的值都放入arr中
                        }
                    }

                    //el-autocomplete元素要求数组内是对象，且有value属性，此处做一个格式调整
                    for (var i = 0; i < arr.length; i++) {
                    var obj = { value: "" };
                    obj.value = arr[i].name;
                    obj.id = arr[i]._id;
                    this.articleNameTipsArray.push(obj);
                    }
                }
            },
            'infoForm.video_name':{
            handler: function() {
                    this.videoNameTipsArray = [];//这是定义好的用于存放下拉提醒框中数据的数组
                    var len = this.videoNameArray.length;//nameArray是页面初始化时全部数据
                    var arr = [];
                    for (var i = 0; i < len; i++) {//根据输入框中inputName的值进行模糊匹配
                        if (this.videoNameArray[i].name.indexOf(this.infoForm.video_name) >= 0) {
                            arr.push(this.videoNameArray[i]);//符合条件的值都放入arr中
                        }
                    }

                    //el-autocomplete元素要求数组内是对象，且有value属性，此处做一个格式调整
                    for (var i = 0; i < arr.length; i++) {
                    var obj = { value: "" };
                    obj.value = arr[i].name;
                    obj.id = arr[i]._id;
                    this.videoNameTipsArray.push(obj);
                    }
                }
            }
        },
        methods: {
            typeChanged(value) {
                this.infoForm.ads_type = value;
            },
            handleGoodsSelect(item) {
                this.infoForm.goods_name = item.value;
                this.infoForm.goods_id = item.id;
                //do something
            },
            handleArticleSelect(item) {
                this.infoForm.article_name = item.value;
                this.infoForm.article_id = item.id;
                //do something
            },
            handleVideoSelect(item) {
                this.infoForm.video_name = item.value;
                this.infoForm.video_id = item.id;
                //do something
            },

            goodsQuerySearch(queruString, cb){
				var nameTipsArray = this.goodsNameTipsArray;
				cb(nameTipsArray);
		    },
            videoQuerySearch(queruString, cb){
                var nameTipsArray = this.videoNameTipsArray;
                cb(nameTipsArray);
            },
            articleQuerySearch(queruString, cb){
                var nameTipsArray = this.articleNameTipsArray;
                cb(nameTipsArray);
            },
            getQiniuToken() {
                let that = this
                this.axios.get('qiniu/getQiniuToken').then((response) => {
                    let resInfo = response.data.data;
                    that.picData.token = resInfo.token;
                    that.url = resInfo.url;
                })
            },
            delePicList() {
                // 不删除服务器上的图片，上传新的自动替换旧的
                this.infoForm.ads_pic_url = '';
            },
            beforeUpload() {
                // 显示loading动画
                this.getQiniuToken();
                this.quillUpdateImg = true
            },
            uploadError() {
                // loading动画消失
                this.quillUpdateImg = false;
                this.$message.error('图片插入失败');
            },
            goBackPage() {
                this.$router.go(-1);
            },
            getGoodsArticleVideoData()
            {
                this.axios.get('home/getGoodsArticleVideoNames').then((response) => {
                    if(response.data.code>0)
                    {
                        this.goodsNameArray = response.data.data.goods;
                        this.videoNameArray = response.data.data.videos;
                        this.articleNameArray = response.data.data.articles;
                        
                    }
                })
            },
            
            getAdsLinkName()
            {
                switch(this.infoForm.ads_type)
                {
                    case 3:
                        if(!this.infoForm.goods_name )
                        {
                            this.$message.error('关联商品不能为空！');
                            return -3;
                        }

                        this.infoForm.link_name = this.infoForm.goods_name;
                        
                    break;
                    case 6:
                        if(!this.infoForm.article_name )
                        {
                            this.$message.error('关联文章不能为空！');
                            return -6;
                        }
                        this.infoForm.link_name = this.infoForm.article_name;
                    break;
                    case 9:
                        if(!this.infoForm.video_name )
                        {
                            this.$message.error('关联视频不能为空！');
                            return -9;
                        }
                        this.infoForm.link_name = this.infoForm.video_name;
                    break;
                    case 12:
                        if(!this.infoForm.web_name||!this.infoForm.web_url )
                        {
                            this.$message.error('关联网页内容不能为空！');
                            return -12;
                        }
                        this.infoForm.link_name = this.infoForm.web_name;
                    break;
                    case 15:

                        if(!this.infoForm.mini_target_name||!this.infoForm.mini_appid ||!this.infoForm.mini_path  )
                        {
                            this.$message.error('关联网页内容不能为空！');
                            return -15;
                        }
                        this.infoForm.link_name = this.infoForm.mini_target_name;
                    break;
                }
            },

            onSubmitInfo() {
                this.$refs['infoForm'].validate((valid) => {
                    if (valid) {
                        
                        var result = this.getAdsLinkName();//根据用户选择的类型 和输入，获取一个通用名称。便于列表展示
                        if(result<0)
                        {
                            return;
                        }

                        var binfo ={
                            ads_pic_url:this.infoForm.ads_pic_url,
                            ads_name:this.infoForm.ads_name,
                            ads_type:this.infoForm.ads_type,
                            link_name:this.infoForm.link_name,
                            link_id:this.infoForm.link_id,

                            goods_name:this.infoForm.goods_name,
                            goods_id:this.infoForm.goods_id,

                            article_id:this.infoForm.article_id,//文章id
                            article_name:this.infoForm.article_name,//文章名称

                            video_id:this.infoForm.video_id,//视频id
                            video_name:this.infoForm.video_name,//视频名称

                            web_url:this.infoForm.web_url,//网页地址
                            web_name:this.infoForm.web_name,//站点目标地址

                            mini_appid:this.infoForm.mini_appid,//小程序appid
                            mini_path:this.infoForm.mini_path,//小程序页面路径，用户传递值
                            mini_target_name:this.infoForm.mini_target_name,//小程序目标页面名称
                        }

                        if(!!this.infoForm._id)
                        {
                            this.axios.post('home/updateAds',
                            {
                                adsinfo: binfo,
                                id:this.infoForm._id
                            }).then((response) => {
                            if (response.data.code> 0) {
                                this.$message({
                                    type: 'success',
                                    message: '更新成功'
                                });
                                this.$router.go(-1);
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '更新失败'
                                })
                            }
                        })
                        }
                        else
                        {
                            this.axios.post('home/addAds',
                            {
                                adsinfo: binfo
                            }).then((response) => {
                            if (response.data.code> 0) {
                                this.$message({
                                    type: 'success',
                                    message: '关联成功'
                                });
                                this.$router.go(-1);
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '关联失败'
                                })
                            }
                        })
                        }
                        
                    } else {
                        return false;
                    }
                });
            },
            handleUploadListSuccess(res) {
                let url = this.url;
                this.infoForm.ads_pic_url = url + res.key;
            },
            initFieldByLinkName()
            {
                if(this.infoForm.ads_type == 3 && !!!this.infoForm.goods_name)
                {
                    this.infoForm.goods_name = this.infoForm.link_name;
                    this.infoForm.goods_id = this.infoForm.link_id;
                }
                else if(this.infoForm.ads_type == 6 && !!!this.infoForm.article_name)
                {
                    this.infoForm.article_name = this.infoForm.link_name;
                    this.infoForm.article_id = this.infoForm.link_id;
                }
                else if(this.infoForm.ads_type == 9 && !!!this.infoForm.video_name)
                {
                    this.infoForm.video_name = this.infoForm.link_name;
                    this.infoForm.video_id = this.infoForm.link_id;
                }
            }
        },
        components: {
        },
        computed: {
        },
        mounted() {
            var adsinfo = this.$route.query.adsinfo;
            if(!!adsinfo)
            {
                if(adsinfo.link_name&&adsinfo.ads_name)
                {
                    this.infoForm = adsinfo;
                    this.initFieldByLinkName();
                }
                
                if(!!adsinfo._id)
                {
                    this.infoForm._id = adsinfo._id;
                }

            }

            this.getQiniuToken();
            this.getGoodsArticleVideoData();
            
            this.root = api.rootUrl;
            this.qiniuZone = api.qiniu;
        },
    }

</script>

<style scoped>
    /* .edit_container{ */
    /*.avatar-uploader .el-upload {*/
    /*display: none;*/
    /*}*/

    .close-i{
		position: absolute;
		top:-8px;
		right: -9px;
		display: none;
		cursor: pointer;
		font-size: 18px;
		background-color: white;
		border-radius: 10px;
	}
	.newtag{
		position: relative;
		cursor: text;
		overflow: visible;
		display: inline-block;
		padding: 0;	
	}
    .addtag
    {
        margin-left: 10px;
    }
	.newtag-content{
		margin: 7px 15px; 
	}
    .dialog-header {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
    }

    .dialog-header .value {
        width: 150px;
        margin-right: 14px;
    }

    .input-wrap .el-input {
        width: 200px;
        float: left;
        margin: 0 20px 20px 0;
    }

    .input-wrap .el-input input {
        background-color: #fff !important;
        color: #233445 !important;
    }

    .specFormDialig .el-input {
        width: 150px;
        margin-right: 10px;
    }

    .el-select-class {
        width: 200px;
        margin-right: 20px;
    }

    .sepc-form {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
    }

    .spec-form-wrap {
        margin-top: 0 !important;
    }

    .add-spec {
        margin-top: 10px;
    }

    .spec-form-wrap .header {
        display: flex;
        justify-content: flex-start;
    }

    .spec-form-wrap .header .l {
        width: 200px;
        margin-right: 20px;
    }

    .spec-form-wrap .header .m {
        width: 200px;
        margin-right: 20px;
    }

    .spec-form-wrap .header .m {
        width: 200px;
        margin-right: 20px;
    }

    /*.sepc-form div{*/
    /*margin-left: 0;*/
    /*}*/

    .float-right {
        float: right;
    }

    .sepc-form .el-input {
        width: 200px;
        margin-right: 20px;
    }

    .marginTop20 {
        margin-top: 20px;
    }

    .checkbox-wrap .checkbox-list {
        float: left;
        margin-right: 20px;
    }

    .upload_ad{
        display: none;
    }
    .upload_ad .el-upload--picture-card {
        display: none;
    }

    .ql-container {
        min-height: 200px;
        max-height: 400px;
        overflow-y: auto;
    }

    .image-uploader-diy {
        /*height: 200px;*/
        position: relative;
    }

    /*.dele-list-pic {*/
        /*position: absolute;*/
        /*left: 380px;*/
        /*top: 0px;*/
    /*}*/

    .image-uploader-diy .el-upload {
        border: 1px solid #d9d9d9;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .image-uploader-diy .el-upload:hover {
        border-color: #20a0ff;
    }

    .image-uploader-diy .image-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 200px;
        height: 200px;
        line-height: 200px;
        text-align: center;
    }

    .image-uploader-diy .image-show {
        min-width: 105px;
        height: 105px;
        display: block;
    }

    .image-uploader-diy .new-image-uploader {
        font-size: 28px;
        color: #8c939d;
        width: 165px;
        height: 105px;
        line-height: 105px;
        text-align: center;
    }

    .image-uploader-diy .new-image-uploader .image-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 165px;
        height: 105px;
        line-height: 105px;
        text-align: center;
    }

    .image-uploader-diy .new-image-uploader .image-show {
        width: 165px;
        height: 105px;
        display: block;
    }

    .item-url-image-fuzhu .el-input {
        width: 260px;
    }

    .hidden {
        display: none;
    }
</style>
